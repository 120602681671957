import React from 'react';

import ErrorText from '@/Components/Input/ErrorText';

export type InputProps = {
  autocomplete?: 'on' | 'off';
  errorText?: string;
  min?: number;
  max?: number;
  name?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  required?: boolean;
  step?: number;
  type?: 'date' | 'email' | 'number' | 'password' | 'phone' | 'text';
  value: number | string | null;
};

const Input: React.FC<InputProps> = (props) => {
  return (
    <div className="w-full">
      <input
        autoComplete={props.autocomplete ?? 'off'}
        className={`w-full flex grow rounded border px-1.5 py-0.75 text-base transition-colors duration-100
            placeholder:text-gray-500 text-gray-900 focus:outline-none focus:ring-2 focus:ring-purple-500 border-gray-200`}
        min={props.min}
        max={props.max}
        name={props.name}
        onChange={props.onChange}
        placeholder={props.placeholder}
        required={props.required}
        step={props.step ?? 1}
        type={props.type ?? 'text'}
        value={props.value ?? ''}
      />

      {props.errorText && (
        <ErrorText className="mt-1" errorText={props.errorText} />
      )}
    </div>
  );
};

export default Input;
